<template>
  <div class="pb-2">
    <portal to="body-top">
      <customer-name title="" />
    </portal>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'home-customers-addresses-create' }"
      trash-list-path="home-customers-addresses-trash"
      :can-create="ACCESS_ABILITY_FOR_ADDRESS"
      :create-btn-dashed="true"
      :can-show-trash-list="ACCESS_ABILITY_FOR_ADDRESS"
      :table-config-options="tableConfigOptions()"
      :is-pagination-available="false"
      create-btn-icon="LAddButtonGreenIcon"
    >
      <template #cell(actions)="{ data }">
        <div class="text-nowrap">
          <Can
            :i="ACCESS_ABILITY_FOR_ADDRESS.action"
            :a="ACCESS_ABILITY_FOR_ADDRESS.subject"
          >
            <feather-icon
              icon="LTrashIconKits"
              size="32"
              class="cursor-pointer mr-1"
              @click="setInActive(data.item)"
            />
            <b-link :to="{ name: 'home-customers-addresses-update', params: { addressId: data.item.id } }">
              <feather-icon
                icon="LStockEyeIcon"
                size="32"
                class="cursor-pointer"
              />
            </b-link>
          </Can>
        </div>
      </template>
      <template #cell(state)="{ data }">
        {{ data.value.name }}
      </template>
      <!--   PLS Don't remove this piece of commented code   === === === === === === === === === ===-->
      <!--      <template #cell(is_default)="{ data }">-->
      <!--        <span-->
      <!--          :id="data"-->
      <!--          class="cursor-pointer d-flex align-items-center position-relative"-->
      <!--          style="width: max-content; height: 20px;  "-->
      <!--          :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"-->
      <!--          @click="setDefault(data)"-->
      <!--        >-->
      <!--          <b-form-checkbox-->
      <!--            :id="data.item.id.toString()"-->
      <!--            :checked="data.item.is_default"-->
      <!--            style="pointer-events: none"-->
      <!--            :disabled="isLoadingOfSetDisabled"-->
      <!--          />-->
      <!--          <b-spinner-->
      <!--            v-if="isSettling(data.item.id)"-->
      <!--            style="margin-bottom: 4px; left: 15px; top: 4px"-->
      <!--            class="ml-1 position-absolute"-->
      <!--            small-->
      <!--            label="Small Spinner"-->
      <!--            type="grow"-->
      <!--            variant="success"-->
      <!--          />-->
      <!--        </span>-->
      <!--      </template>-->
      <!--   PLS Don't remove this piece of commented code   === === === === === === === === === ===-->
      <template #cell(type)="{ data }">
        <feather-icon
          :icon="data.item.verified ? 'LTickIcon': 'LWarningIcon'"
          size="16"
        />
        <span class="align-middle">
          {{ getTypeText(data.item) }}
        </span>
      </template>
    </l-table>
    <div
      v-if="accountHolderNotification || billingAddressNotification || shippingAddressNotification || canOrderPlaceNotification"
      class="notification-section"
    >
      <div
        v-if="accountHolderNotification"
        class="notification alert"
      >
        <p class="font-weight-bolder">
          {{ $t('Account Holder is Missing!') }}
        </p>
        <p>
          {{ $t('Account Holder is required for all Accounts before they can place any Purchase or Rental Orders or get verified by ID Scan.') }}
        </p>
      </div>
      <div
        v-if="billingAddressNotification"
        class="notification alert"
      >
        <p class="font-weight-bolder">
          {{ $t('Billing Address is Missing!') }}
        </p>
        <p>
          {{ $t('Billing Address is required for all Accounts before they can place any Purchase or Rental Orders.') }}
        </p>
      </div>
      <div
        v-if="shippingAddressNotification"
        class="notification alert"
      >
        <p class="font-weight-bolder">
          {{ $t('Shipping Address is Missing!') }}
        </p>
        <p>
          {{ $t('Shipping Address is required for all Accounts before they can place any Purchase or Rental Orders.') }}
        </p>
      </div>
      <div
        v-if="canOrderPlaceNotification"
        class="notification alert"
      >
        <p class="font-weight-bolder">
          {{ $t('Can Place Order is Missing!') }}
        </p>
        <p>
          {{ $t('Can Place Order is required for all Accounts before they can place any Purchase or Rental Orders.') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import LTable from '@/views/components/LTable/LTable.vue'
import { BLink } from 'bootstrap-vue'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import CustomerName from '../../../components/CustomerName.vue'
import config from '../addressConfig'

export default {
  name: 'AddressList',
  components: {
    BLink,
    LTable,
    CustomerName,
    // eslint-disable-next-line vue/no-unused-components
    ButtonDashed,
  },
  data() {
    return {
      billing: '',
      shipping: '',
      account_holder: '',
      can_place_orders: '',
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  computed: {
    addresses() {
      return this.$store.state[this.MODULE_NAME].addresses
    },
    accountHolderNotification() {
      return this.account_holder === 0
    },
    billingAddressNotification() {
      return this.billing === 0
    },
    shippingAddressNotification() {
      return this.shipping === 0
    },
    canOrderPlaceNotification() {
      // return this.can_place_orders === 0
    },
    // tableConfigOptions() {
    //   return {
    //     data: {
    //       order_type: 'desc',
    //     },
    //   }
    // },
  },
  watch: {
    addresses(newAddresses) {
      this.updateAddressCounts(newAddresses)
    },
  },
  methods: {
    checkAddressVerified(data) {
      console.log('ina: ', data.item.verified)
    },
    tableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/getActiveAddressList`,
      }
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    setInActive(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/setInActive`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.refetchData()
        })
    },
    update(data) {
      this.$router.push({
        name: 'home-customers-addresses-update',
        params: { address: data.id },
      })
    },
    getTableConfigOptions() {
      return {
        getData: response => response.data.data,
        getTotal: () => 0,
      }
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    getTypeText(data) {
      const arr = []
      if (data.billing) arr.push('Billing')
      if (data.shipping) arr.push('Shipping')
      if (data.account_holder) arr.push('Account Holder')
      return arr.join(', ')
    },
    updateAddressCounts(data) {
      this.billing = 0
      this.shipping = 0
      this.account_holder = 0
      this.can_place_orders = 0
      if (!data) {
        return
      }
      for (const item of data) {
        if (item?.billing) {
          this.billing++
        }
        if (item?.shipping) {
          this.shipping++
        }
        if (item?.account_holder) {
          this.account_holder++
        }
        if (item?.can_place_orders) {
          this.can_place_orders++
        }
      }
    },
    setDefault(data) {
      const {
        item: { id },
      } = data
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        {
          id,
        },
        `${this.MODULE_NAME}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },
  },
  setup() {
    const MODULE_NAME = 'address'
    const { tableColumns, ACCESS_ABILITY_FOR_ADDRESS } = config()
    return {
      tableColumns,
      MODULE_NAME,
      ACCESS_ABILITY_FOR_ADDRESS,
    }
  },
}
</script>
<style scoped lang="scss">
.notification-section {
  margin-bottom: 20px;
  width: 50%;
}
.notification {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  background-color: #FFF3CD;
  border: 1px solid #dde3e7;
}
</style>
